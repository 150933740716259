import Vue from "vue";
import App from "./App.vue";
import vueCustomElement from "vue-custom-element";
import CookieConsentVue from "./plugins/CookieConsentVue.js";

Vue.config.productionTip = false;

Vue.use(vueCustomElement);
Vue.use(CookieConsentVue);

App.CookieConsentVue = CookieConsentVue;

Vue.customElement("cookie-consent-widget", App);
