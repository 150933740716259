import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import langEN from "../locales/en.json";
import langFR from "../locales/fr.json";

const config = {
  // Options to tweak the modal's and button's layout/position
  guiOptions: {
    consentModal: {
      layout: "box inline",
      position: "bottom right",
    },
    preferencesModal: {
      layout: "box",
    },
  },
  // For more config reference:
  // https://cookieconsent.orestbida.com/reference/configuration-reference.html

  // Function trigger on the user's first consent action.
  onFirstConsent: () => {},

  // Function trigger on the user's first consent action and after each page load
  onConsent: () => {},

  // Function executed when the user's preferences are changing.
  onChange: () => {},

  // Config to define your category cookies
  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
    ads: {},
  },

  language: {
    default: "en",
    translations: { en: langEN, fr: langFR },
  },
};

export default {
  install: (Vue) => {
    Vue.prototype.$CookieConsent = CookieConsent;
    Vue.prototype.$CookieConsent.run(config);
  },
};
