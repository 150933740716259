<script>
export default {
  name: "CookieConsent",
  props: {
    msg: String,
    lang: String,
  },
  mounted() {
    // Set langages to the cookie consent
    this.$CookieConsent.setLanguage(this.lang);
  },
};
</script>

<style scoped></style>
