<template>
  <div data-vuetify>
    <CookieConsent
      :redirect="redirect || true"
      :position="position || true"
      :lang="lang"
    />
  </div>
</template>

<script>
import CookieConsent from "./components/CookieConsent.vue";

export default {
  name: "App",
  components: {
    CookieConsent,
  },
  props: {
    redirect: Boolean,
    position: Boolean,
    lang: String,
  },
};
</script>
